<template>
    <el-dialog v-model="openDialogVisible" title="修改密码" width="30%" center :before-close="beforeClose">
        <el-form :model="ruleForm" :rules="rules" ref="ruleForm" label-width="130px" class="demo-ruleForm">
            <el-form-item label="旧密码：" prop="old_password">
                <el-input v-model="ruleForm.old_password" type="password"></el-input>
            </el-form-item>
            <el-form-item label="新密码：" prop="password">
                <el-input v-model="ruleForm.password" type="password"></el-input>
            </el-form-item>
            <el-form-item label="确认新密码：" prop="re_password">
                <el-input v-model="ruleForm.re_password" type="password"></el-input>
            </el-form-item>
        </el-form>
        <template #footer>
            <span class="dialog-footer">
                <el-button @click="cancle">取消</el-button>
                <el-button type="primary" @click="handleSubmit()">确认</el-button>
            </span>
        </template>
    </el-dialog>
</template>
  
<script>
import { ElMessage } from 'element-plus';

export default {
    props: {
        open: {
            type: Boolean,
            required: true,
        }
    },
    data() {
        return {
            ruleForm: {
                old_password: '',
                password: '',
                re_password: '',
            },
            openDialogVisible: this.open,
            rules: {
                old_password: [
                    { required: true, message: '旧密码不能为空', trigger: 'blur' },
                ],
                password: [
                    { required: true, min: 6, message: '新密码必须为6位以上字符', trigger: 'blur' },
                ],
                re_password: [
                    { required: true, message: '确认新密码不能为空', trigger: 'blur' },
                    { validator: this.validateRePass, trigger: 'blur' },
                ],
            },
        };
    },
    watch: {
        open(newVal) {
            this.openDialogVisible = newVal;
        }
    },
    methods: {
        cancle() {
            this.$emit('update:open', false);
            this.$refs.ruleForm.resetFields();
            this.openDialogVisible = false;
        },
        beforeClose() {
            this.$refs.ruleForm.resetFields();
            this.$emit('update:open', false);
        },
        async handleSubmit() {
            try {
                const valid = await new Promise((resolve) => {
                    this.$refs.ruleForm.validate((valid) => {
                        resolve(valid);
                    });
                });

                if (valid) {
                    let r;
                    r = await this.$api.reSetPassword.reSetAgent(this.ruleForm);
                    if (r.status == 200) {
                        // 处理成功逻辑
                        sessionStorage.clear()
                        this.$router.replace({ path: '/login' });
                        localStorage.clear()
                        window.location.reload()
                    }


                } else {
                    ElMessage({
                        message: '请先完成填写',
                        type: 'error',
                    });
                }
            } catch (error) {
                ElMessage({
                    message: '修改密码错误，请稍后重试',
                    type: 'error',
                });
                // 处理异常情况
            }
        },
        validateRePass(rule, value, callback) {
            if (value !== this.ruleForm.password) {
                callback(new Error('两次输入密码不一致!'));
            } else {
                callback();
            }
        },
    },
};
</script>
  